import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { VSpacing } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import HeaderTitle from 'src/components/Employer/HeaderTitle';
import EmployerPageTabContent from 'src/components/Employer/PageTabs/EmployerPageTabContent';
import EmployerPageTabs from 'src/components/Employer/PageTabs/EmployerPageTabs';
import { usePageTabsContext, EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';
import EmployerPageTitle, { TileMode } from 'src/components/Employer/PageTitle';
import Sidebar from 'src/components/Employer/Sidebar';
import VacanciesGroupsByRegion from 'src/components/Employer/VacanciesGroupsByRegion';
import SearchFooterButton from 'src/components/Employer/VacancySearch/SearchFooterButton';
import AddWidget from 'src/components/EmployerConstructor/AddWidget';
import EmployerReviewsWidgetBig from 'src/components/EmployerReviews/WidgetBig';
import EmployerReviewsWidgetSmall from 'src/components/EmployerReviews/WidgetSmall';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';

import DefaultDescription from 'src/pages/EmployerSimpleEdit/components/DefaultDescription';
import EditDescription from 'src/pages/EmployerSimpleEdit/components/EditDescription';

const setAutoAddWidget = makeSetStoreField('employerConstructorAutoAddWidget');

const EmployerSimpleEditContent: FC = () => {
    const editMode = useSelector((state) => state.employerInfo.canEditDescription && !state.employerPageIsViewMode);
    const employerName = useSelector((state) => state.employerInfo.name);
    const hasConstructorService = useSelector((state) => state.employerConstructor.hasService);
    const employerHasDivisions = useSelector((state) => state.employerHasDivisions);
    const employerAccepted = useSelector((state) => state.employerInfo.accepted);

    const [showDescriptionEditor, toggleShowDescriptionEditor] = useToggleState(false);
    const dispatch = useDispatch();
    const isZarplata = useIsZarplataPlatform();

    const { isEmployerTabsExp } = usePageTabsContext();

    const currentBreakpoint = useBreakpoint();
    const isXs = currentBreakpoint === Breakpoint.XS;
    const isLargeScreen = [Breakpoint.M, Breakpoint.L].includes(currentBreakpoint);
    const showHeaderReviewsWidget = employerAccepted && isLargeScreen;
    const showBottomReviewsWidget = employerAccepted && !editMode;

    const isEmployerConstructorAllowed = hasConstructorService || !employerHasDivisions || isZarplata;
    const isAddEmployerConstructorWidgetAllowed =
        (hasConstructorService || !employerHasDivisions) && editMode && !isZarplata && !isXs;

    return (
        <EmployerPageTitle mode={editMode ? TileMode.EditDescription : TileMode.View} employerName={employerName}>
            <div className="employer-constructor-xs-hidden">
                <Gap bottom />
            </div>
            <ColumnsWrapper>
                <Column xs="4" s="8" m="3" l="3">
                    <Sidebar editMode={editMode} showConstructorButton={isEmployerConstructorAllowed} />
                </Column>
                <Column xs="4" s="8" m="9" l="11">
                    <div className="sticky-container">
                        <div className="company-header">
                            <HeaderTitle isMainHeader={isLargeScreen} />
                            {showHeaderReviewsWidget && <EmployerReviewsWidgetSmall />}
                        </div>
                        <EmployerPageTabs />
                        <EmployerPageTabContent tab={EmployerPageTab.Description}>
                            <Gap top>
                                {showDescriptionEditor ? (
                                    <EditDescription toggleShowDescriptionEditor={toggleShowDescriptionEditor} />
                                ) : (
                                    <DefaultDescription
                                        toggleShowDescriptionEditor={toggleShowDescriptionEditor}
                                        editMode={editMode}
                                    />
                                )}
                            </Gap>
                            {isAddEmployerConstructorWidgetAllowed && (
                                <Gap top bottom>
                                    <AddWidget
                                        widgets={[]}
                                        disableToggleOpened={true}
                                        onClick={({ type }) => {
                                            dispatch(setAutoAddWidget(type));
                                            dispatch(
                                                push(
                                                    `/employer/constructor?from=add_${type.toLowerCase()}&hhtmFromLabel=add_${type.toLowerCase()}`
                                                )
                                            );
                                        }}
                                    />
                                </Gap>
                            )}
                            {showBottomReviewsWidget && <EmployerReviewsWidgetBig />}
                        </EmployerPageTabContent>
                        {!editMode && (
                            <EmployerPageTabContent tab={EmployerPageTab.Vacancies}>
                                {isEmployerTabsExp && <VSpacing default={24} />}
                                <VacanciesGroupsByRegion hasChameleon={isEmployerTabsExp} />
                            </EmployerPageTabContent>
                        )}
                    </div>
                </Column>
                <SearchFooterButton />
            </ColumnsWrapper>
        </EmployerPageTitle>
    );
};

export default EmployerSimpleEditContent;
