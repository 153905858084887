import PropTypes from 'prop-types';

import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import { CameraScaleMedium, IconColor } from 'bloko/blocks/icon';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';

import translation from 'src/components/translation';
import { Status } from 'src/models/employerConstructor';

const HeaderEditPromoUploadButton = ({ trls, getInputProps, resizeStatus, allowedMimeTypes, onLoad }) => {
    return (
        <HoverTip render={() => trls[HeaderEditPromoUploadButton.trls.uploadTooltip]}>
            <label className="employer-constructor-header__promo-upload-button">
                {resizeStatus === Status.Fetching ? (
                    <Loading initial={LoadingColor.Gray80} scale={LoadingScale.Medium} />
                ) : (
                    <span className="employer-constructor-header__promo-upload-icon">
                        <CameraScaleMedium initial={IconColor.White} />
                    </span>
                )}
                <input
                    {...getInputProps()}
                    type="file"
                    className="g-hidden"
                    accept={allowedMimeTypes}
                    onChange={onLoad}
                    data-qa="constructor-head__promo-upload-image-input"
                />
            </label>
        </HoverTip>
    );
};

HeaderEditPromoUploadButton.trls = {
    uploadTooltip: 'employer.constructor.header.promo.upload.tooltip',
};

HeaderEditPromoUploadButton.propTypes = {
    trls: PropTypes.object,
    getInputProps: PropTypes.func,
    allowedMimeTypes: PropTypes.arrayOf(PropTypes.string),
    resizeStatus: PropTypes.string,
    onLoad: PropTypes.func,
};

export default translation(HeaderEditPromoUploadButton);
