import { useCallback, useEffect, useState } from 'react';

import { Button } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import SessionStorageWrapper from 'bloko/common/storage/SessionStorageWrapper';
import urlParser from 'bloko/common/urlParser';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    buttonText: 'employer.view.chameleon.vacancies.filtered.link',
};

const VacanciesLink: TranslatedComponent = ({ trls }) => {
    const employerId = useSelector(({ employerInfo }) => employerInfo.id);

    const [vacanciesLink, setVacanciesLink] = useState('');

    const updateLinkParams = useCallback(() => {
        const payloadFilterParams = SessionStorageWrapper.getItem('VacancyBlockFilter');
        const url = urlParser(`/employer/${employerId || ''}/vacancies/`);
        if (payloadFilterParams) {
            url.params = {
                vacanciesFilter: payloadFilterParams,
            };
        }
        setVacanciesLink(url.href);
    }, [employerId]);

    useEffect(() => {
        window.addEventListener('VacancyBlockFilter', updateLinkParams);
        return () => window.removeEventListener('VacancyBlockFilter', updateLinkParams);
    }, [updateLinkParams]);

    useEffect(() => updateLinkParams(), [updateLinkParams]);

    return (
        <Button
            Element={Link}
            to={vacanciesLink}
            size="medium"
            style="neutral"
            mode="secondary"
            stretched
            data-qa="employer-page-tabs-xs-go-filtered-vacancies"
        >
            {trls[TrlKeys.buttonText]}
        </Button>
    );
};

export default translation(VacanciesLink);
