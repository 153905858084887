import { Fragment, useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import Button, { ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import FormSpacer from 'bloko/blocks/form/FormSpacer';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import saveDescriptionRequest from 'src/api/employer/saveDescription';
import TextContentEditor from 'src/components/Employer/TextContentEditor';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { Status } from 'src/models/employer/employerInfo';

import {
    employerSaveDescriptionError,
    employerSaveDescriptionSuccess,
    ErrorType,
} from 'src/pages/EmployerSimpleEdit/components/Notifications';

const TrlKeys = {
    saveDescription: 'employer.edit.simple.saveDescription',
    cancelEditDescription: 'employer.edit.simple.cancelEditDescription',
};

interface EditDescriptionProps {
    toggleShowDescriptionEditor: () => void;
}

const EditDescription: TranslatedComponent<EditDescriptionProps> = ({ trls, toggleShowDescriptionEditor }) => {
    const descriptionText = useSelector((state) => state.employerInfo.description);
    const canEdit = useSelector((state) => state.employerInfo.canEditDescription && !state.employerPageIsViewMode);
    const saveDescriptionStatus = useSelector((state) => state.employerInfo.saveDescriptionStatus);
    const dispatch = useDispatch();

    const [description, setDescription] = useState(descriptionText);
    const [waitSaveStatus, setWaitSaveStatus] = useState(false);
    const { addNotification } = useNotification();

    const saveDescription = useCallback(() => {
        setWaitSaveStatus(true);
        dispatch(saveDescriptionRequest(description)).catch((error: AxiosError<{ error?: { key?: ErrorType }[] }>) => {
            addNotification(employerSaveDescriptionError, {
                props: {
                    type: error?.response?.data?.error?.[0]?.key || 'UNKNOWN',
                },
            });
        });
    }, [addNotification, description, dispatch]);

    useEffect(() => {
        if (saveDescriptionStatus === Status.Success && waitSaveStatus) {
            addNotification(employerSaveDescriptionSuccess);
            setWaitSaveStatus(false);
            toggleShowDescriptionEditor();
            setDescription(descriptionText);
        }
    }, [addNotification, descriptionText, saveDescriptionStatus, toggleShowDescriptionEditor, waitSaveStatus]);

    return (
        <Fragment>
            <TextContentEditor value={description} onChange={setDescription} />
            <VSpacing base={6} />
            {canEdit && (
                <>
                    <Button
                        onClick={saveDescription}
                        kind={ButtonKind.Primary}
                        data-qa="save-employer-description"
                        loading={saveDescriptionStatus === Status.Fetching && <Loading scale={LoadingScale.Small} />}
                    >
                        {trls[TrlKeys.saveDescription]}
                    </Button>
                    <FormSpacer>
                        <Button
                            onClick={toggleShowDescriptionEditor}
                            kind={ButtonKind.Primary}
                            appearance={ButtonAppearance.Outlined}
                        >
                            {trls[TrlKeys.cancelEditDescription]}
                        </Button>
                    </FormSpacer>
                    <VSpacing base={6} />
                </>
            )}
        </Fragment>
    );
};

export default translation(EditDescription);
