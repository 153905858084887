import classNames from 'classnames';

import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import { InfoScaleSmall, IconColor } from 'bloko/blocks/icon';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { PriceWithFormatData } from 'src/models/price/priceBranding/priceBranding.types';
import { NARROW_NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    changeLink: 'employer.price.branding.periodItem.change',
    regionTip: 'employer.price.branding.regionTip',
    regionEtc: 'employer.price.branding.regionEtc',
};

export interface CustomSwitcherData {
    slimKind?: boolean;
    prefixText?: string;
    selectedRegions: PriceWithFormatData[];
    onClickRegionSwitcher: () => void;
}

const SelectRegionSwitcher: TranslatedComponent<CustomSwitcherData> = ({
    prefixText,
    slimKind,
    trls,
    selectedRegions,
    onClickRegionSwitcher,
}) => {
    return (
        <div className="price-branding-region-switcher">
            <span
                className="price-branding-region-switcher__link"
                data-qa="branding-region-popup-switcher"
                onClick={onClickRegionSwitcher}
            >
                <Text Element="span">{trls[TrlKeys.changeLink]}</Text>
            </span>
            <div
                data-qa="constructor-region-list"
                className={classNames('price-branding-region-switcher__list', {
                    'price-branding-region-switcher__list_slim': slimKind,
                })}
            >
                {prefixText}
                {selectedRegions[0].priceRegionTrl}
                {selectedRegions.length > 1 &&
                    `${NARROW_NON_BREAKING_SPACE}${trls[TrlKeys.regionEtc]}${NARROW_NON_BREAKING_SPACE}${
                        selectedRegions.length - 1
                    }`}
                {NARROW_NON_BREAKING_SPACE}
                <HoverTip render={() => trls[TrlKeys.regionTip]}>
                    <InfoScaleSmall initial={IconColor.Gray50} />
                </HoverTip>
            </div>
        </div>
    );
};

export default translation(SelectRegionSwitcher);
