import { useCallback, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import saveDescriptionRequest from 'src/api/employer/saveDescription';
import useSelectRegion from 'src/components/EmployerConstructor/price/useSelectRegion';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import useScroll from 'src/hooks/useScroll';
import { setEditMode, Status, setSaveDescriptionErrorType } from 'src/models/employerConstructor';
import saveConstructorTemplate from 'src/pages/EmployerConstructor/components/saveConstructorTemplate';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import ControlContentEditMode from 'src/pages/EmployerConstructor/components/Controls/ControlContentEditMode';
import ControlContentPreviewMode from 'src/pages/EmployerConstructor/components/Controls/ControlContentPreviewMode';

const Controls = ({
    editMode,
    hasUnsavedChanges,
    infoHasUnsavedChanges,
    saveStatus,
    setEditMode,
    saveConstructorTemplate,
    saveDescriptionRequest,
    saveDescriptionStatus,
    description,
    tabPrices,
    employerId,
    setSaveDescriptionErrorType,
    trls,
    hasService,
    defaultRegionsId,
}) => {
    const [sticked, setSticked] = useState(false);
    const derivedEditMode = useRef(editMode);
    const controlsRef = useRef(null);
    const breakpoint = useBreakpoint(Breakpoint.L);
    const isZP = useIsZarplataPlatform();
    const toggleEditMode = useCallback(() => {
        derivedEditMode.current = !editMode;
        setEditMode(!editMode);
    }, [editMode, setEditMode]);
    const { addNotification } = useNotification();

    const saveConstructor = useCallback(() => {
        saveDescriptionRequest(description)
            .then(() => saveConstructorTemplate(addNotification))
            .catch((error) => {
                setSaveDescriptionErrorType(error?.response?.data?.error?.[0]?.key || 'UNKNOWN');
            });
    }, [addNotification, description, saveConstructorTemplate, saveDescriptionRequest, setSaveDescriptionErrorType]);

    useEffect(() => {
        if (!derivedEditMode.current) {
            return;
        }

        setEditMode(breakpoint !== Breakpoint.XS);
    }, [breakpoint, setEditMode]);

    useScroll(() => {
        const elementOffsetTop = controlsRef.current.offsetHeight + controlsRef.current.offsetTop;
        const elementIsSticked = window.scrollY + window.innerHeight > elementOffsetTop;
        setSticked(elementIsSticked);
    });

    const saveInProgress = saveDescriptionStatus === Status.Fetching || saveStatus === Status.Fetching;
    const hasSave = hasUnsavedChanges || infoHasUnsavedChanges;

    const selectRegionProps = useSelectRegion({
        defaultRegionsId,
        prices: tabPrices,
        slimKind: true,
        hasService,
        prefixText: `${trls[Controls.trls.regionPrefix]}:${NON_BREAKING_SPACE}`,
    });

    return (
        <div
            className={classnames('employer-constructor-controls', {
                'employer-constructor-controls_sticked': sticked && editMode,
                'employer-constructor-controls__hide': isZP && !editMode,
            })}
            ref={controlsRef}
        >
            <ColumnsWrapper>
                <Column xs="4" s="8" m="12" l="16">
                    {editMode ? (
                        <ControlContentEditMode
                            saveConstructor={saveConstructor}
                            hasSave={hasSave}
                            employerId={String(employerId)}
                            toggleEditMode={toggleEditMode}
                            saveInProgress={saveInProgress}
                            tabPrices={tabPrices}
                            hasService={hasService}
                            selectRegionProps={selectRegionProps}
                        />
                    ) : (
                        <ControlContentPreviewMode toggleEditMode={toggleEditMode} />
                    )}
                </Column>
            </ColumnsWrapper>
        </div>
    );
};

Controls.propTypes = {
    trls: PropTypes.object,
    tabPrices: PropTypes.arrayOf(
        PropTypes.shape({
            priceRegionId: PropTypes.string.isRequired,
            priceRegionTrl: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
            currency: PropTypes.string.isRequired,
        })
    ),
    employerId: PropTypes.number,
    editMode: PropTypes.bool,
    hasUnsavedChanges: PropTypes.bool,
    infoHasUnsavedChanges: PropTypes.bool,
    saveStatus: PropTypes.string,
    setEditMode: PropTypes.func,
    description: PropTypes.string,
    saveConstructorTemplate: PropTypes.func,
    saveDescriptionStatus: PropTypes.string,
    saveDescriptionRequest: PropTypes.func,
    setSaveDescriptionErrorType: PropTypes.func,
    defaultRegionsId: PropTypes.array,
    expirationTime: PropTypes.string,
    hasService: PropTypes.bool,
};

Controls.trls = {
    regionPrefix: 'employer.constructor.controls.regionPrefix',
};

export default connect(
    ({ employerConstructor, employerInfo }) => ({
        editMode: employerConstructor.editMode,
        tabPrices: employerConstructor.tabPrices,
        hasUnsavedChanges: employerConstructor.hasUnsavedChanges,
        infoHasUnsavedChanges: employerInfo.hasUnsavedChanges,
        saveStatus: employerConstructor.saveStatus,
        description: employerInfo.description,
        saveDescriptionStatus: employerInfo.saveDescriptionStatus,
        employerId: employerInfo.id,
        defaultRegionsId: employerConstructor.defaultPriceRegions,
        hasService: employerConstructor.hasServiceExpiration,
    }),
    { setEditMode, saveConstructorTemplate, saveDescriptionRequest, setSaveDescriptionErrorType }
)(translation(Controls));
