import { useCallback, useEffect, Fragment, useState, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import imageCropSettingsPropType from 'src/components/EmployerConstructor/imageCropSettingsPropType';
import resizeImage from 'src/components/EmployerConstructor/resizeImage';
import uploadImage from 'src/components/EmployerConstructor/uploadImage';
import ImageCropPopup from 'src/components/ImageCropPopup';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import {
    employerConstructorUpdateHeader,
    Status,
    setModalError as setModalErrorAction,
} from 'src/models/employerConstructor';
import { PictureType } from 'src/models/employerConstructor/settings';

import HeaderBase from 'src/pages/EmployerConstructor/components/HeaderBase';
import HeaderEditControls from 'src/pages/EmployerConstructor/components/HeaderEditControls';
import HeaderEditPromoUploadButton from 'src/pages/EmployerConstructor/components/HeaderEditPromoUploadButton';

const HeaderEdit = ({
    pictureId,
    resizeStatus,
    images,
    trls,
    resizeImage,
    uploadImage,
    employerConstructorUpdateHeader,
    imageCropSettings,
    modalError,
    setModalError,
}) => {
    const [cropPictureId, setCropPictureId] = useState(null);
    const { addNotification } = useNotification();

    const cropPicture = useMemo(
        () => images.find((image) => image.pictureId === cropPictureId),
        [cropPictureId, images]
    );
    const resetModalError = useCallback(() => setModalError(null), [setModalError]);

    /**
     * Чистим ошибка crop модалки при закрытии окна
     */
    useEffect(() => {
        !cropPictureId && resetModalError();
    }, [resetModalError, cropPictureId]);

    const onLoad = useCallback(
        (event) => {
            const imageFile = event.target ? event.target.files[0] : event[0];

            if (event.target) {
                event.target.value = null;
            }

            uploadImage(
                {
                    file: imageFile,
                    pictureType: PictureType.EmployerPageBackground,
                },
                addNotification
            ).then(
                (data) => {
                    setCropPictureId(data.pictureId);
                    employerConstructorUpdateHeader({
                        resizeStatus: Status.Dirty,
                    });
                },
                () => {}
            );
        },
        [addNotification, employerConstructorUpdateHeader, uploadImage]
    );

    const removeHeaderImage = useCallback(() => {
        employerConstructorUpdateHeader({
            pictureId: null,
        });
    }, [employerConstructorUpdateHeader]);

    const onSavePicture = useCallback(
        (cropParams) => {
            if (cropParams.noChanges) {
                pictureId !== cropPictureId &&
                    employerConstructorUpdateHeader({
                        pictureId: cropPictureId,
                    });
                setCropPictureId(null);
                return;
            }

            resetModalError();
            resizeImage(
                {
                    resizeParams: {
                        pictureId: cropPictureId,
                        ...cropParams.absoluteSizes,
                    },
                },
                {
                    showErrorAction: setModalErrorAction,
                },
                addNotification
            ).then(
                () => setCropPictureId(null),
                () => {}
            );
        },
        [resetModalError, resizeImage, cropPictureId, addNotification, pictureId, employerConstructorUpdateHeader]
    );

    return (
        <Fragment>
            <Dropzone multiple={false} disableClick={true} onDrop={onLoad}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                        {...getRootProps()}
                        className={classnames('employer-constructor-header-edit', {
                            'employer-constructor-header-edit_drop': isDragActive,
                        })}
                    >
                        <HeaderBase>
                            {pictureId ? (
                                <HeaderEditControls
                                    allowedMimeTypes={imageCropSettings.allowedMimeTypes}
                                    resizeStatus={resizeStatus}
                                    onLoad={onLoad}
                                    getInputProps={getInputProps}
                                    enhancedToggleCropPopupVisible={() => setCropPictureId(pictureId)}
                                    removeHeaderImage={removeHeaderImage}
                                />
                            ) : (
                                <HeaderEditPromoUploadButton
                                    getInputProps={getInputProps}
                                    resizeStatus={resizeStatus}
                                    allowedMimeTypes={imageCropSettings.allowedMimeTypes}
                                    onLoad={onLoad}
                                />
                            )}
                        </HeaderBase>
                    </div>
                )}
            </Dropzone>
            <ImageCropPopup
                error={modalError}
                onClose={() => setCropPictureId(null)}
                onSave={onSavePicture}
                visible={!!cropPicture}
                onDragStop={resetModalError}
                resizeInProgress={resizeStatus === Status.Fetching}
                imageCropSettings={{
                    src: cropPicture?.originalPath,
                    stateX: cropPicture?.selectionLeft,
                    stateY: cropPicture?.selectionTop,
                    stateWidth: cropPicture?.selectionWidth,
                    stateHeight: cropPicture?.selectionHeight,
                    originalWidth: cropPicture?.originalWidth,
                    originalHeight: cropPicture?.originalHeight,
                    ratio: imageCropSettings.widthHeightRatio,
                    minimumWidth: imageCropSettings.minWidth,
                    minimumHeight: imageCropSettings.minHeight,
                }}
                title={trls[HeaderEdit.trls.cropPupupTitle]}
                description={trls[HeaderEdit.trls.cropPupupDescription]}
                save={trls[HeaderEdit.trls.cropPupupSave]}
                cancel={trls[HeaderEdit.trls.cropPupupCancel]}
            />
        </Fragment>
    );
};

HeaderEdit.propTypes = {
    trls: PropTypes.object,
    pictureId: PropTypes.number,
    resizeStatus: PropTypes.string,
    images: PropTypes.array,
    uploadImage: PropTypes.func.isRequired,
    resizeImage: PropTypes.func.isRequired,
    employerConstructorUpdateHeader: PropTypes.func,
    imageCropSettings: imageCropSettingsPropType,
    modalError: PropTypes.string,
    setModalError: PropTypes.func,
};

HeaderEdit.defaultProps = {
    images: [],
    imageCropSettings: {},
};

HeaderEdit.trls = {
    cropPupupTitle: 'employer.constructor.header.cropimage.title',
    cropPupupDescription: 'employer.constructor.header.cropimage.description',
    cropPupupSave: 'employer.constructor.cropimage.button.save',
    cropPupupCancel: 'employer.constructor.cropimage.button.cancel',

    uploadNew: 'employer.constructor.header.uploadNew',
};

export default connect(
    ({ employerConstructor, employerConstructorSettings }) => ({
        pictureId: employerConstructor.pictureId,
        resizeStatus: employerConstructor.resizeStatus,
        modalError: employerConstructor.modalError,
        images: employerConstructor.images,
        imageCropSettings: employerConstructorSettings.pictureSettings[PictureType.EmployerPageBackground],
    }),
    {
        resizeImage,
        uploadImage,
        employerConstructorUpdateHeader,
        setModalError: setModalErrorAction,
    }
)(translation(HeaderEdit));
